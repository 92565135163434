<template>
  <div>
    <div class="cardList">
      <visits-card-component :visitsCardItem="visitsCard" />
    </div>
    <van-button type="primary" class="setJZ" @click="upgrade"
      >升级就诊卡</van-button
    >
    <van-button type="primary" class="setJZ-disabled" @click="goBack"
      >返回</van-button
    >
    <div class="delete" @click="handleDelete">删除就诊卡</div>
  </div>
</template>

<script>
import VisitsCardComponent from "@/components/visitsCard";
export default {
  name: "upgrade",
  components: { VisitsCardComponent },
  data() {
    return {
      loading: false,
      wechatCode: "",
      visitsCard: null,
    };
  },
  created() {
    this.visitsCard = JSON.parse(sessionStorage.getItem("visitsCard"));
    this.getWechatCodeStart();
  },
  methods: {
    /**
     * 获取微信授权码
     */
    getWechatCodeStart() {
      let wechatCode = "";
      let url = location.href;
      if (url.indexOf("wechatCode") === -1) {
        let redirectUrl = `${domain}index.html%23%2Fupgrade`;
        window.location.replace(
          `https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirectUrl}`
        );
      } else {
        // 从url里面取wechatCode
        wechatCode = url.split("?")[1].split("#")[0].split("=")[1];
        // url没有说明还没跳转
        if (wechatCode !== null || wechatCode !== "") {
          sessionStorage.setItem("wechatCode", wechatCode);
          this.wechatCode = wechatCode;
        }
      }
    },

    /**
     * 点击就诊卡升级为健康卡
     */
    upgrade() {
      if (this.wechatCode === "") {
        this.getWechatCodeStart();
      }
      this.loading = true;
      let postData = {
        wechatCode: sessionStorage.getItem("wechatCode"),
        patientId: this.visitsCard.patientId,
        idCard: this.visitsCard.idCard,
        openId: this.visitsCard.openId,
      };
      this.$healthHttpService
        .post("healthapi/api/health/upgrade", postData)
        .then((res) => {
          this.loading = false;
          if (res.data) {
            this.$notify({
              type: "success",
              message: "升级成功",
            });
            this.$router.go(-2);
          }
        })
        .catch(() => {
          this.$notify({
            type: "warning",
            message: "升级失败，建议删除就诊卡后重新注册电子健康卡",
          });
          this.loading = false;
        });
    },
    /**
     * 返回建卡首页
     * */
    goBack() {
      this.$router.replace({ path: "/healthCard" });
    },
    // 删除就诊卡
    handleDelete() {
      let postData = {
        idNumber: this.visitsCard.idCard,
        userid: this.visitsCard.openId,
        patid: this.visitsCard.patientId,
      };
      this.$http.delete("/api/card", postData).then((res) => {
        if (res.data) {
          this.$notify({
            type: "success",
            message: "删除成功",
          });
          this.$router.go(-2);
        }
      });
    },
  },
  destroyed() {
    sessionStorage.removeItem("visitsCard");
  },
};
</script>
<style scoped>
.cardList {
  margin: 0.2rem 0;
  display: flex;
  flex-flow: column;
}
.noCard {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-pat {
  width: 9rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #59fdc5, #00d48b);
  margin-bottom: 0.5rem;
  font-size: 0.4rem;
}

.add-pat .van-icon {
  position: relative;
  top: -1px;
  left: -2px;
}
.psText span {
  color: #1bdc9b;
}

.setJZ {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  border: none;
  background-image: linear-gradient(to right, #6dc0ff, #189bff);
  margin-top: 30px;
}
.setJZ-disabled {
  margin: auto;
  border-radius: 5px;
  position: relative;
  width: 4rem;
  border: none;
  background-color: #afafaf;
  margin-top: 30px;
  margin-left: 0.5rem;
}
.delete {
  display: block;
  margin: 0.5rem auto;
  color: #44aefe;
  border: none;
  font-size: 0.4rem;
}
</style>
